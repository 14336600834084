<template>
  <div v-if="loading" class="d-flex align-center justify-center" style="min-height:200px">
    <v-progress-circular indeterminate color="primary" />
  </div>
  <div v-else>
    <dashboard-modal-chart-settings
      v-model="isOpenSettingsPayments"
      :settings="settingsChart"
      :mode="mode"
      @change-settings="changeSettings"
    />

    <dashboard-modal-filters
      v-if="media.isMobile"
      v-model="isOpenFiltersPayments"
      :value-filters="dashboardFiltersValuePayments"
      :mode="mode"
      @input-filters="inputFiltersMobile"
    />

    <dashboard-filters v-else v-model="dashboardFiltersValuePayments" :mode="mode" />

    <dashboard-modal-period
      v-if="media.isMobile"
      v-model="isOpenPeriodPayments"
      :period-filter="dashboardFiltersValuePayments.datePeriod"
      @input-period="inputPeriod"
    />

    <dashboard-summary
      :loading="loading"
      :cards="cardSummary"
      :units-measurement="unitsMeasurementChart"
      :mode="mode"
    />

    <dashboard-graph
      :settings="settingsChart"
      :dataset="currentChartData"
      :graphic-type="graphicType"
      :units-measurement="unitsMeasurementChart"
      :period-to="filterPeriodTo"
      :message-text="messageTextChart"
      @change-settings="changeSettings"
    />
  </div>
</template>

<script>
import DashboardModalChartSettings from '@/components/Dashboard/DashboardModalChartSettings.vue';
import DashboardModalFilters from '@/components/Dashboard/DashboardModalFilter.vue';
import DashboardFilters from '@/components/Dashboard/DashboardFilters.vue';
import DashboardModalPeriod from '@/components/Dashboard/DashboardModalPeriod.vue';
import DashboardSummary from '@/components/Dashboard/DashboardSummary.vue';
import DashboardGraph from '@/components/Dashboard/Graph.vue';

import { getSettingsPaymentsChart } from '@/components/Dashboard/graph.config';

import { isEqual, isEmptyObject } from '@/utils/common';
import { flushPromises } from '@/utils/scheduler';
import { checkEmptyParams, extractParamsFromMultiSelectObject } from '@/utils/many';
import client from '@/http/client';
import dashboardService from '@/services/dashboard';

import { PAYMENTS, TASKS } from '@/constants/dashboardModes';

const createDefaultCardSummary = () => ({
  invoicesIssued: null,
  invoicesPaid: null,
  invoicesPending: null,
});

const createDefaultPeriod = () => ({
  invoicesIssued: [],
  invoicesPaid: [],
  invoicesPending: [],
});

export default {
  name: 'DashboardPayments',
  components: {
    DashboardModalChartSettings,
    DashboardModalFilters,
    DashboardFilters,
    DashboardModalPeriod,
    DashboardSummary,
    DashboardGraph,
  },
  props: {
    mode: { type: String, default: PAYMENTS },
    isOpenSettings: { type: Boolean, default: false },
    isOpenFilters: { type: Boolean, default: false },
    isOpenPeriod: { type: Boolean, default: false },
    dashboardFiltersValue: { type: Object, required: true },
  },
  data() {
    return {
      settingsChart: getSettingsPaymentsChart(),
      chartData: {
        forChosenPeriod: {
          invoicesIssued: [],
          invoicesPaid: [],
          invoicesPending: [],
        },
        forAllTime: {
          invoicesIssued: [],
          invoicesPaid: [],
          invoicesPending: [],
        },
      },
      loading: false,
      cardSummary: {},
      graphicType: '',
      filterPeriodTo: '',
      messageTextChart: '',
    };
  },
  inject: ['media'],
  computed: {
    currentChartData() {
      return this.dashboardFiltersValuePayments.accounting?.value
        ? this.chartData.forChosenPeriod
        : this.chartData.forAllTime;
    },
    unitsMeasurementChart() {
      return this.dashboardFiltersValuePayments.unitsMeasurement?.value;
    },
    isOpenSettingsPayments: {
      get() {
        return this.isOpenSettings;
      },
      set(newVal) {
        if (this.isOpenSettingsPayments) this.$emit('close-settings');
        return newVal;
      },
    },
    isOpenPeriodPayments: {
      get() {
        return this.isOpenPeriod;
      },
      set(newVal) {
        if (this.isOpenPeriodPayments) this.$emit('close-period');
        return newVal;
      },
    },
    isOpenFiltersPayments: {
      get() {
        return this.isOpenFilters;
      },
      set(newVal) {
        if (this.isOpenFiltersPayments) this.$emit('close-filters');
        return newVal;
      },
    },
    dashboardFiltersValuePayments: {
      get() {
        return this.dashboardFiltersValue;
      },
      set(newVal) {
        if (this.dashboardFiltersValue) this.$emit('set-filters', newVal);
        return newVal;
      },
    },
    isPaymentsMode() {
      return this.mode === PAYMENTS;
    },
    isTasksMode() {
      return this.mode === TASKS;
    },
  },
  watch: {
    dashboardFiltersValuePayments: {
      async handler(newVal, oldVal) {
        if (!isEqual(newVal, oldVal)) await this.initDashboard(newVal);
      },
      deep: true,
    },
  },
  mounted() {
    this.initDashboard(this.dashboardFiltersValuePayments).then(res => {
      if (isEmptyObject(res)) {
        this.$emit('missing-data');
      }
    });
  },
  methods: {
    async initDashboard({ datePeriod, unitsMeasurement, servicesTypes, projects, clients, contractors, paymentTypes }) {
      if (this.$options.cancelSource) {
        this.$options.cancelSource.cancel();
        await flushPromises();
      }

      this.loading = true;

      try {
        const cancelSource = client.getCancelToken();
        this.$options.cancelSource = cancelSource;

        const requestBody = this.normalizeFilters({ servicesTypes, projects, clients, contractors, paymentTypes });

        const {
          summary,
          forAllTime,
          forChosenPeriod,
          graphicType,
          dateTo,
        } = await dashboardService.updateCompanyGraphicData(
          {
            invoiceDateFrom: datePeriod[0],
            invoiceDateTo: datePeriod[1],
            unitsMeasurement: unitsMeasurement?.value,
            ...requestBody,
          },
          { cancelToken: cancelSource.token }
        );

        this.setInfo({ summary, forAllTime, forChosenPeriod, graphicType, dateTo });

        return { summary, forAllTime, forChosenPeriod, graphicType, dateTo };
      } finally {
        this.loading = false;
        this.$options.cancelSource = null;
      }
    },
    // async setFilter({ datePeriod, unitsMeasurement, servicesTypes, projects, clients, contractors, paymentTypes }) {
    //   if (this.$options.cancelSource) {
    //     this.$options.cancelSource.cancel();
    //     await flushPromises();
    //   }

    //   this.loading = true;

    //   try {
    //     const cancelSource = client.getCancelToken();
    //     this.$options.cancelSource = cancelSource;

    //     const requestBody = this.normalizeFilters({ servicesTypes, projects, clients, contractors, paymentTypes });

    //     const {
    //       summary,
    //       forAllTime,
    //       forChosenPeriod,
    //       graphicType,
    //       dateTo,
    //     } = await dashboardService.updateCompanyGraphicData(
    //       {
    //         invoiceDateFrom: datePeriod[0],
    //         invoiceDateTo: datePeriod[1],
    //         unitsMeasurement: unitsMeasurement?.value,
    //         ...requestBody,
    //       },
    //       { cancelToken: cancelSource.token }
    //     );

    //     this.setInfo({ summary, forAllTime, forChosenPeriod, graphicType, dateTo });
    //   } finally {
    //     this.loading = false;
    //     this.$options.cancelSource = null;
    //   }
    // },
    setInfo({ summary, forAllTime, forChosenPeriod, graphicType, dateTo }) {
      this.messageTextChart = '';

      this.cardSummary = summary || createDefaultCardSummary();
      this.graphicType = graphicType || '';
      this.filterPeriodTo = dateTo || '';

      if (forAllTime && forChosenPeriod) {
        this.setChartData(forAllTime, forChosenPeriod);
      } else {
        this.messageTextChart = 'label.not_data_filter';
        this.setChartData(createDefaultPeriod(), createDefaultPeriod());
      }
    },
    normalizeFilters({ servicesTypes, projects, clients, contractors, paymentTypes }) {
      return {
        serviceTypes: checkEmptyParams(servicesTypes) ? undefined : extractParamsFromMultiSelectObject(servicesTypes),
        projects: checkEmptyParams(projects) ? undefined : extractParamsFromMultiSelectObject(projects),
        clients: checkEmptyParams(clients) ? undefined : extractParamsFromMultiSelectObject(clients),
        contractors: checkEmptyParams(contractors) ? undefined : extractParamsFromMultiSelectObject(contractors),
        paymentTypes: checkEmptyParams(paymentTypes) ? undefined : extractParamsFromMultiSelectObject(paymentTypes),
      };
    },
    setChartData(forAllTime, forChosenPeriod) {
      this.chartData = { forAllTime, forChosenPeriod };
    },
    inputPeriod(dates) {
      this.dashboardFiltersValuePayments = { ...this.dashboardFiltersValuePayments, datePeriod: dates };
    },
    inputFiltersMobile(filters) {
      this.dashboardFiltersValuePayments = filters;
    },
    changeSettings(settings) {
      this.settingsChart = settings;
    },
  },
  cancelSource: null,
};
</script>
