<template>
  <base-modal
    v-model="modelWrapper"
    class="dashboard-modal-settings-payments"
    content-class="dashboard-modal-settings-payments"
    :title="$t('dashboard.payments_settings')"
  >
    <v-row>
      <v-col v-for="setting in configSettings" :key="setting.id">
        <div class="dashboard-modal-settings-payments__header-col">
          <div class="dashboard-modal-settings-payments__indicator" :style="`background: ${setting.color};`"></div>
          <h6 class="dashboard-modal-settings-payments__title-col">{{ $t(setting.title) }}</h6>
        </div>
        <base-switch v-model="localSettings[setting.value].display" :label="$t('dashboard.graph_display_settings')" />
        <base-switch
          v-model="localSettings[setting.value].isShowValue"
          :disabled="!localSettings[setting.value].display"
          :label="$t('dashboard.values_settings')"
        />
      </v-col>
    </v-row>
    <v-row justify="end">
      <v-btn color="secondary" large class="mr-2 primary--text" elevation="0" @click="close">
        {{ $t('modal_button.back') }}
      </v-btn>
      <v-btn color="blue20" large class="white--text" elevation="0" @click="saveSettings">
        {{ $t('modal_button.save') }}
      </v-btn>
    </v-row>
  </base-modal>
</template>

<script>
import BaseModal from '@/components/BaseModal.vue';
import BaseSwitch from '@/components/BaseSwitch.vue';

import { clone } from '@/utils/clone';
import { createModelWrapper } from '@/utils/components';
import { PAYMENTS } from '@/constants/dashboardModes';
import { getSettingsPaymentsChart, getSettingsTasksChart } from './graph.config';

const configSettingsPayments = [
  { id: 1, title: 'dashboard.invoices_issue', color: '#C7D6FF', value: 'issue' },
  { id: 2, title: 'dashboard.invoices_paid', color: '#0C30F7', value: 'paid' },
  { id: 3, title: 'dashboard.invoices_unpaid', mobileTitle: 'dashboard.unpaid', color: '#FF5252', value: 'unpaid' },
];

const configSettingsTasks = [
  { id: 1, title: 'dashboard.tasks_pending', color: '#FF5252', value: 'pending' },
  { id: 2, title: 'dashboard.tasks_in_progress', color: '#002FFF', value: 'inProgress' },
  { id: 3, title: 'dashboard.tasks_canceled', mobileTitle: 'Canceled', color: '#BFBFBF', value: 'canceled' },
  { id: 4, title: 'dashboard.tasks_completed', mobileTitle: 'Completed', color: '#00008D', value: 'completed' },
];

export default {
  name: 'DashboardModalChartSettings',
  components: { BaseModal, BaseSwitch },
  model: {
    prop: 'isOpen',
    event: 'update:isOpen',
  },
  props: {
    isOpen: { type: Boolean, default: false },
    settings: { type: Object, required: true },
    mode: { type: String, default: PAYMENTS },
  },
  data() {
    return {
      localSettings: this.mode === PAYMENTS ? getSettingsPaymentsChart() : getSettingsTasksChart(),
      configSettings: this.mode === PAYMENTS ? configSettingsPayments : configSettingsTasks,
    };
  },
  computed: {
    modelWrapper: createModelWrapper('isOpen', 'update:isOpen'),
  },
  watch: {
    isOpen: {
      immediate: true,
      handler(value) {
        if (value) {
          this.localSettings = clone(this.settings);
        }
      },
    },
    localSettings: {
      handler(newSettings) {
        this.checkIsShowValue(newSettings);
      },

      deep: true,
    },
    async mode() {
      this.localSettings = this.mode === PAYMENTS ? getSettingsPaymentsChart() : getSettingsTasksChart();
      this.configSettings = this.mode === PAYMENTS ? configSettingsPayments : configSettingsTasks;
    },
  },
  methods: {
    saveSettings() {
      this.$emit('change-settings', this.localSettings);
      this.close();
    },
    checkIsShowValue(settings) {
      if (settings) {
        Object.keys(settings).forEach(key => {
          if (!this.localSettings[key]?.display && this.localSettings[key]?.isShowValue) {
            this.localSettings[key].isShowValue = false;
          }
        });
      }
    },
    close() {
      this.modelWrapper = false;
    },
  },
  configSettingsPayments,
  configSettingsTasks,
};
</script>

<style lang="scss">
.dashboard-modal-settings-payments {
  max-width: 800px !important;
  &__header-col {
    display: flex;
    align-items: center;
    margin-bottom: 18px;
    flex-wrap: nowrap;
    min-width: 145px;
  }
  &__indicator {
    width: 8px;
    height: 8px;
    border-radius: 100px;
    margin-right: 8px;
  }
  &__title-col {
    font-weight: $--font-weight-medium;
    font-size: 16px;
    line-height: 24px;
    color: $--black-color-0;
    @media (max-width: map.get($--screens, 'sm')) {
      font-size: 14px;
      line-height: 20px;
    }
  }
  @media (max-width: map.get($--screens, 'sm')) {
    label.v-label.theme--light {
      font-size: 12px;
      line-height: 20px;
    }
  }
}
</style>
