/* eslint-disable */
import client from '@/http/client';
import { download, repackResponseFile } from '@/utils/common';

const BASE_URL = '/dashboard';

class DashboardService {
  getCompanySummaryInfo() {
    return client.get(`${BASE_URL}/company/summary_info/`)
  }

  getCompanyGraphicData() {
    return client.get(`${BASE_URL}/company/graphic_data/`)
  }

  updateCompanyGraphicData({invoiceDateFrom, invoiceDateTo, unitsMeasurement, serviceTypes, projects, clients, contractors, paymentTypes}, config,) {
    return client.post(`${BASE_URL}/company/graphic_data/`,
    {
      serviceTypes,
      projects,
      clients,
      contractors,
      paymentTypes,
    },
    {
      ...config,
      params: {
        invoiceDateFrom,
        invoiceDateTo,
        formatBy: unitsMeasurement,
    },
    }
    )

  }

  getCompanyGraphicDataTasks({taskDateFrom, taskDateTo, unitsMeasurement, serviceTypes, projects, clients, performers}, config,) {
    return client.post(`${BASE_URL}/company/graphic_data_tasks/`,
      {
        serviceTypes,
        projects,
        clients,
        performers,
      },
      {
        ...config,
        params: {
          taskDateFrom,
          taskDateTo,
          formatBy: unitsMeasurement,
      },
      }
    )

  }

  exportCompanyGraphicData ({invoiceDateFrom, invoiceDateTo, unitsMeasurement, serviceTypes, projects, clients} = {},) {
    return client.postRaw(
      `${BASE_URL}/company/graphic_data/xlsx/`,
      {
        serviceTypes,
        projects,
        clients
      },
      {
        params: {
          invoiceDateFrom,
          invoiceDateTo,
          formatBy: unitsMeasurement,
      },
        responseType: 'blob',
      }
    );
  }

  async downloadCompanyGraphicData(fallbackFilename, config) {
    const response = await this.exportCompanyGraphicData(config);

    const { filename, blob } = repackResponseFile(response, fallbackFilename);

    download(filename, blob);
  }
}

export default new DashboardService();
