<template>
  <div class="v-chart-bottom-line" :style="cssVars"></div>
</template>

<script>
export default {
  name: 'VChartBottomLine',

  props: {
    left: { type: [Number, String], default: 0 },
    top: { type: [Number, String], default: 0 },
    opacity: { type: [Number, String], default: 0 },
  },

  computed: {
    cssVars() {
      return {
        '--v-chart-bottom-line-top': `${this.top}px`,
        '--v-chart-bottom-line-left': `${this.left}px`,
        '--v-chart-bottom-line-opacity': this.opacity,
      };
    },
  },
};
</script>

<style lang="scss">
.v-chart-bottom-line {
  position: absolute;
  left: var(--v-chart-bottom-line-left, 0);
  top: var(--v-chart-bottom-line-top, 0);
  opacity: var(--v-chart-bottom-line-opacity, 0);
  height: 1px;
  background: $--blue-color-20;
  width: 32px;
  transform: translateX(-50%);
}
</style>
