<template>
  <base-missing-data class="dashboard-missing-data" :icon="$options.IconMissingDataDashboard" :message="message">
    <template #actions>
      <v-btn v-if="isPaymentsMode" :to="invoiceRoute" color="primary" large elevation="0">
        {{ $t('button.open_invoice') }}
        <v-icon right>mdi-menu-right</v-icon>
      </v-btn>
      <v-btn v-if="isTasksMode" :to="taskRoute" color="primary" large elevation="0">
        {{ $t('button.open_task') }}
        <v-icon right>mdi-menu-right</v-icon>
      </v-btn>
    </template>
  </base-missing-data>
</template>

<script>
import BaseMissingData from '@/components/BaseMissingData.vue';
import IconMissingDataDashboard from '@/components/MissingDataIcons/MissingDataDashboard.vue';

import { INVOICES, TASKS } from '@/constants/routes';
import { PAYMENTS, TASKS as TASKS_MODE } from '@/constants/dashboardModes';

export default {
  name: 'DashboardMissingData',

  components: { BaseMissingData },

  props: {
    mode: { type: String, default: TASKS },
  },

  computed: {
    invoiceRoute() {
      return { name: INVOICES };
    },
    taskRoute() {
      return { name: TASKS };
    },
    isPaymentsMode() {
      return this.mode === PAYMENTS;
    },
    isTasksMode() {
      return this.mode === TASKS_MODE;
    },
    message() {
      if (this.isPaymentsMode) {
        return this.$t('dashboard.missing_message');
      }
      if (this.isTasksMode) {
        return this.$t('dashboard.missing_message_tasks');
      }

      return '';
    },
  },

  IconMissingDataDashboard,
};
</script>
