<template>
  <div class="v-chart-background" :style="cssVars"></div>
</template>

<script>
export default {
  name: 'VChartBackground',

  props: {
    left: { type: [Number, String], default: 0 },
    width: { type: [Number, String], default: 0 },
    height: { type: [Number, String], default: 0 },
    opacity: { type: [Number, String], default: 0 },
  },

  computed: {
    cssVars() {
      return {
        '--v-chart-background-left': `${this.left}px`,
        '--v-chart-background-width': `${this.width}px`,
        '--v-chart-background-height': `${this.height}px`,
        '--v-chart-background-opacity': this.opacity,
      };
    },
  },
};
</script>

<style lang="scss">
.v-chart-background {
  position: absolute;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(121, 151, 255, 0.15) 85.22%);
  border-radius: 3px;
  pointer-events: none;
  transition: all 0.1s ease;
  transform: translateX(calc(-50% - 8px));
  top: 0;
  left: var(--v-chart-background-left, 0);
  width: var(--v-chart-background-width, 0);
  height: var(--v-chart-background-height, 0);
  opacity: var(--v-chart-background-opacity, 0);
}
</style>
