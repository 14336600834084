<template>
  <svg viewBox="0 0 252 251" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="125.988" cy="125.152" r="125.152" fill="#80BAFF" fill-opacity=".8" />
    <rect x="53" y="53.001" width="24" height="116" rx="2" fill="#E6EBFF" />
    <rect x="90" y="77.001" width="24" height="92" rx="2" fill="#E6EBFF" />
    <rect x="122" y="100" width="24" height="70" rx="2" fill="#E6EBFF" />
    <path
      d="M173.609 70.797c-1.991 8.012 1.564 15.792 7.941 17.377 6.377 1.584 13.161-3.626 15.152-11.638 1.991-8.013.496-24.082-5.881-25.667-6.378-1.585-15.221 11.916-17.212 19.928ZM125.142 89.187c4.62 8 13.51 11.516 19.857 7.852 6.348-3.665 7.748-13.122 3.129-21.123-4.619-8-18.289-19.794-24.636-16.13-6.348 3.665-2.969 21.4 1.65 29.4ZM79.881 133.392c1.717 2.974 5.021 4.28 7.38 2.918 2.36-1.362 2.88-4.877 1.163-7.85-1.717-2.974-6.797-7.357-9.156-5.995-2.36 1.362-1.104 7.953.613 10.927Z"
      fill="#00008D"
    />
    <path
      d="M202.95 117.151C202.95 90.558 185.5 69 163.975 69 142.45 69 125 90.558 125 117.15v.212a32.28 32.28 0 0 0 .211 3.637H101.5C85.208 121 72 134.207 72 150.5c0 2.669.355 5.255 1.019 7.714A9.464 9.464 0 0 0 72 162.5v18c0 5.246 4.253 9.5 9.5 9.5s9.5-4.254 9.5-9.5v-2.424a29.304 29.304 0 0 0 7 1.718v.706a9.5 9.5 0 0 0 19 0v-.5h18v.5a9.5 9.5 0 0 0 19 0v-.5h15.5c.842 0 1.676-.036 2.5-.105v.605a9.5 9.5 0 0 0 19 0v-9.801a29.399 29.399 0 0 0 8-20.199c0-4.991-1.239-9.692-3.427-13.813 3.926-3.189 5.953-7.865 6.844-12.235.286-1.405.456-2.778.533-4.06v-3.241Z"
      fill="#00008D"
    />
    <path
      d="M73.019 158.214A29.532 29.532 0 0 1 72 150.5v12c0-1.542.367-2.998 1.019-4.286ZM195.573 136.687A29.367 29.367 0 0 1 199 150.5l3.417-26.048c-.891 4.37-2.918 9.046-6.844 12.235Z"
      fill="#00008D"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M202.786 121.999c-7.788 11.751-21.132 19.5-36.286 19.5-19.126 0-35.37-12.345-41.198-29.5 0 0-.802 2.5-.802 4.5s.5 4.577.5 4.577c5.552 17.634 22.032 30.423 41.5 30.423 14.051 0 26.546-6.663 34.499-17 1.6-3.2 1.858-9.667 1.787-12.5Z"
      fill="#002FFF"
    />
    <rect x="141" y="124" width="46" height="59" rx="5" fill="#E6EBFF" />
    <rect x="144" y="127" width="40" height="53" rx="2" fill="#fff" />
    <path fill="#E6EBFF" d="M148 140.999h5v31h-5zM156 150h5v22h-5zM165 157h5v15h-5zM173 157h5v15h-5z" />
    <path
      d="M152 170.999c1-5.5 1.6-9.5 6-9.5 5.5 0 4.5-12 8-12s2.5 6 6 6c2.8 0 4.167-12 5-15"
      stroke="#C7D6FF"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <ellipse cx="152" cy="103.999" rx="5" ry="6" transform="rotate(180 152 103.999)" fill="#fff" />
    <circle cx="156.5" cy="101.5" r="4.5" transform="rotate(180 156.5 101.5)" fill="#00008D" />
    <ellipse cx="177" cy="103" rx="5" ry="6" transform="rotate(180 177 103)" fill="#fff" />
    <circle cx="180.5" cy="101.5" r="4.5" transform="rotate(180 180.5 101.5)" fill="#00008D" />
    <circle cx="158" cy="123.999" r="8" fill="#00008D" />
    <circle cx="171" cy="123" r="8" fill="#00008D" />
    <path d="M160 118.499c0 1.381 5 2.5 5 2.5s5-1.119 5-2.5-2.239-2.5-5-2.5-5 1.119-5 2.5Z" fill="#FF5252" />
    <path
      d="M232.53 44.14c2.915-.493 5.592.964 5.979 3.254.388 2.29-1.661 4.546-4.575 5.04-2.915.493-8.608-.453-8.995-2.743-.388-2.29 4.677-5.057 7.591-5.55Z"
      fill="#E6EBFF"
    />
    <path
      d="M231.735 55.607c.494 2.915-.963 5.592-3.253 5.98-2.29.387-4.546-1.662-5.04-4.576-.493-2.915.453-8.607 2.744-8.995 2.29-.387 5.056 4.677 5.549 7.591Z"
      fill="#E6EBFF"
    />
    <path
      d="M221.837 56.03c-2.915.493-5.591-.964-5.979-3.254-.387-2.29 1.661-4.546 4.576-5.04 2.914-.492 8.607.454 8.994 2.744.388 2.29-4.676 5.057-7.591 5.55Z"
      fill="#E6EBFF"
    />
    <path
      d="M222.624 44.56c-.493-2.915.963-5.592 3.253-5.98 2.29-.387 4.547 1.661 5.04 4.576.493 2.914-.453 8.607-2.743 8.994-2.29.388-5.057-4.676-5.55-7.59Z"
      fill="#E6EBFF"
    />
    <rect
      x="231.797"
      y="52.332"
      width="1.552"
      height="11.726"
      rx=".776"
      transform="rotate(126.604 231.797 52.332)"
      fill="#C7D6FF"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconMissingDataDashboard',
};
</script>
