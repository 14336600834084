<template>
  <div class="dashboard">
    <v-chart-tooltip
      ref="tooltipChart"
      :left="tooltip.left"
      :opacity="tooltip.opacity"
      :top="tooltip.top"
      :data-points="tooltip.dataPoints"
    />
    <v-chart-background
      :left="background.left"
      :opacity="background.opacity"
      :width="background.width"
      :height="background.height"
    />
    <v-chart-bottom-line :top="bottomLine.top" :left="bottomLine.left" :opacity="bottomLine.opacity" />

    <v-chart-message :opacity="messageOpacity" :message="messageText" />

    <v-chart
      ref="chart"
      :datasets="chartConfig.datasets"
      :options="chartConfig.options"
      :plugins="chartConfig.plugins"
      :labels="labels"
    />
  </div>
</template>

<script>
import VChart from '@/components/VChart/VChart.vue';
import VChartTooltip from '@/components/VChart/Tooltip.vue';
import VChartBackground from '@/components/VChart/Background.vue';
import VChartBottomLine from '@/components/VChart/BottomLine.vue';
import VChartMessage from '@/components/VChart/Message.vue';
import { clone } from '@/utils/clone';
import { separateThouthands } from '@/utils/priceFormatting';
import { format, subDays } from 'date-fns';
import { translateDate, translateShortDate } from '@/utils/dateFormatting';
import formatUnitsValue from '@/utils/unitsFormatting';
import { getConfigTasksChartjs, COLOR_TICKS } from './graph.config';

const colorDatalabels = 'rgba(77, 77, 87, 0.5)';

export default {
  name: 'GraphTasks',

  components: { VChart, VChartTooltip, VChartBackground, VChartBottomLine, VChartMessage },
  props: {
    settings: { type: Object, required: true },
    dataset: { type: Object, required: true },
    graphicType: { type: String, required: true },
    periodTo: { type: String, default: '' },
    messageText: { type: String, default: '' },
  },

  inject: ['media'],

  data() {
    return {
      bottomLine: {
        top: 0,
        left: 0,
        opacity: 0,
      },

      tooltip: {
        top: 0,
        left: 0,
        dataPoints: [],
        opacity: 0,
      },

      background: {
        left: 0,
        width: 0,
        height: 0,
        opacity: 0,
      },
      localSettings: {},
    };
  },

  computed: {
    messageOpacity() {
      return this.messageText ? 0.4 : 0;
    },
    labels() {
      return this.formatLabels(this.setLabels());
    },
    dataSetsPending() {
      return {
        data: this.formatDates(this.dataset.pendingTasks),
        hidden: !this.localSettings.pending?.display,
        datalabels: {
          color: colorDatalabels,
          align: 'top',
          formatter: value => this.datalabelsFormatter(value),
          display: this.localSettings.pending?.isShowValue,
        },
      };
    },
    dataSetsInProgress() {
      return {
        data: this.formatDates(this.dataset.inProgressTasks),
        hidden: !this.localSettings.inProgress?.display,
        datalabels: {
          color: colorDatalabels,
          align: 'top',
          formatter: value => this.datalabelsFormatter(value),
          display: this.localSettings.inProgress?.isShowValue,
        },
      };
    },
    dataSetsCanceled() {
      return {
        data: this.formatDates(this.dataset.canceledTasks),
        hidden: !this.localSettings.canceled?.display,
        datalabels: {
          color: colorDatalabels,
          align: 'end',
          offset: -5,
          anchor: 'end',
          formatter: value => this.datalabelsFormatter(value),
          display: this.localSettings.canceled?.isShowValue,
        },
      };
    },
    dataSetsCompleted() {
      return {
        data: this.formatDates(this.dataset.completedTasks),
        hidden: !this.localSettings.completed?.display,
        datalabels: {
          color: colorDatalabels,
          align: 'end',
          offset: -5,
          anchor: 'end',
          formatter: value => this.datalabelsFormatter(value),
          display: this.localSettings.completed?.isShowValue,
        },
      };
    },
    chartConfig() {
      const {
        onLeave,
        dataSetsPending,
        dataSetsInProgress,
        dataSetsCanceled,
        dataSetsCompleted,
        media,
        tooltipController,
        onHover,
        onLegendClick,
        dataSetsLabel,
        barWidth,
        formatTicksScaleY,
      } = this;
      return getConfigTasksChartjs({
        onLeave,
        dataSetsPending,
        dataSetsInProgress,
        dataSetsCanceled,
        dataSetsCompleted,
        isMobile: media.isMobile,
        tooltipController,
        onHover,
        onLegendClick,
        dataSetsLabel,
        barWidth,
        formatTicksScaleY,
      });
    },
    dataSetsLabel() {
      return {
        pending: this.$t('dashboard.tasks_pending'),
        inProgress: this.$t('dashboard.tasks_in_progress'),
        canceled: this.$t('dashboard.tasks_canceled'),
        completed: this.$t('dashboard.tasks_completed'),
      };
    },
    barWidth() {
      if (this.media.xs) {
        if (this.labels.length < 7) {
          return 16;
        }
        if (this.labels.length > 10) {
          return 'flex';
        }
        return 8;
      }
      if (this.media.sm) {
        if (this.labels.length < 16) {
          return 16;
        }
        return 8;
      }
      return 16;
    },
  },

  watch: {
    settings: {
      handler(newSettings) {
        this.localSettings = clone(newSettings);
      },

      deep: true,
      immediate: true,
    },
  },

  methods: {
    datalabelsFormatter(value) {
      return separateThouthands(value);
    },
    onHover(mouseEvent) {
      const chart = mouseEvent?.chart;

      chart.options.scales.x.ticks.autoSkip = false;
      const scaleX = chart.scales.x;
      const tickIndex = scaleX.getValueForPixel(mouseEvent.x);

      const colors = [];

      // eslint-disable-next-line
      for (let i = 0; i < scaleX._valueRange; ++i) {
        if (i === tickIndex) {
          colors.push('#002FFF');
        } else {
          colors.push('#00000000');
        }
      }

      chart.config.options.scales.x.ticks.color = colors;
      mouseEvent.chart.update();
    },

    async onLeave(tempChart) {
      // так как chart.js перед этим еще раз вызывает вызов tooltip
      // необходимо ждать пока сработает метод tooltipController
      await this.$nextTick();
      this.tooltip.opacity = 0;
      this.background.opacity = 0;
      this.bottomLine.opacity = 0;

      const chart = tempChart?.scales ? tempChart : tempChart?.chart;
      chart.options.scales.x.ticks.autoSkip = true;

      chart.config.options.scales.x.ticks.color = [COLOR_TICKS];
      this.$refs.chart.update();
    },

    async tooltipController(context) {
      const { chart, tooltip } = context;
      const { offsetLeft: positionX } = chart.canvas;
      const { width, ticks } = chart.scales.x;
      const xTickWidth = width / ticks.length;
      const backgroundPositionLeft = tooltip.caretX;

      const canvasWidth = chart.canvas.offsetWidth;

      const dataPointsTooltip = tooltip.dataPoints.map(item => ({
        dataIndex: item.dataIndex,
        datasetIndex: item.datasetIndex,
        element: item.element,
        dataset: item.dataset,
        raw: { y: this.formatTicksScaleY(item.raw) },
      }));
      // устанавливаем значение, чтобы была ширина тултипа
      this.setTooltipDataPoints(dataPointsTooltip);
      // ждем пока значения установятся
      await this.$nextTick();
      const widthTooltip = this.$refs.tooltipChart.$el.offsetWidth;

      if (widthTooltip / 2 > tooltip.caretX) {
        this.setTooltipProperty({
          left: positionX,
          top: 0,
          dataPoints: dataPointsTooltip,
        });
      } else if (widthTooltip / 2 > canvasWidth - tooltip.caretX) {
        this.setTooltipProperty({
          left: positionX + tooltip.caretX - widthTooltip + (canvasWidth - tooltip.caretX),
          top: 0,
          dataPoints: dataPointsTooltip,
        });
      } else {
        this.setTooltipProperty({
          left: positionX + tooltip.caretX - widthTooltip / 2,
          top: 0,
          dataPoints: dataPointsTooltip,
        });
      }

      this.setBackgroundProperty({
        left: backgroundPositionLeft + 8,
        width: xTickWidth,
        height: chart.chartArea.height + 50,
      });

      this.setBottomLineProperty({ left: positionX + tooltip.caretX, top: chart.chartArea.height + 56 });
    },

    formatTicksScaleY(value) {
      return formatUnitsValue({ value, units: this.unitsMeasurement });
    },

    translateScaleX(valueX, idx, nextLabel) {
      if (this.graphicType === 'daily') {
        return [translateDate(format(new Date(valueX), 'd L')), format(new Date(valueX), 'yyyy')];
      }
      if (this.graphicType === 'weekly') {
        return [
          `${this.$t('chart.week')} ${idx + 1}`,
          `${format(new Date(valueX), 'd.LL')} ${
            nextLabel
              ? `- ${format(subDays(new Date(nextLabel), 1), 'd.LL')}`
              : `${format(new Date(this.periodTo), 'd.LL')}`
          }`,
        ];
      }
      if (this.graphicType === 'monthly') {
        return translateShortDate(format(new Date(valueX), 'L-yyyy')).split(' ');
      }
      if (this.graphicType === 'yearly') {
        return format(new Date(valueX), 'yyyy');
      }
      return translateDate(format(new Date(valueX), 'd L yyyy'));
    },

    formatDates(dates) {
      return dates.map(data => {
        return data.y;
      });
    },

    formatLabels(labels) {
      return labels.map((label, idx) => this.translateScaleX(label, idx, labels[idx + 1]));
    },

    setLabels() {
      const labels = [];
      Object.values(this.dataset).forEach(data => {
        data.forEach(item => {
          labels.push(item.x);
        });
      });

      return Array.from(new Set(labels));
    },

    setBackgroundProperty({ left, width, height }) {
      this.background = { left, width, height, opacity: 1 };
    },

    setTooltipProperty({ left, top, dataPoints }) {
      this.tooltip = { left, top, dataPoints, opacity: 1 };
    },

    setTooltipDataPoints(dataPoints) {
      this.tooltip.dataPoints = dataPoints;
    },

    setBottomLineProperty({ left, top }) {
      this.bottomLine = { left, top, opacity: 1 };
    },

    onLegendClick(_, legendItem) {
      switch (legendItem.text) {
        case this.dataSetsLabel.pending:
          this.localSettings.pending.display = this.dataSetsPending.hidden;
          break;

        case this.dataSetsLabel.inProgress:
          this.localSettings.inProgress.display = this.dataSetsInProgress.hidden;
          break;

        case this.dataSetsLabel.canceled:
          this.localSettings.canceled.display = this.dataSetsCanceled.hidden;
          break;

        case this.dataSetsLabel.completed:
          this.localSettings.completed.display = this.dataSetsCompleted.hidden;
          break;

        default:
          break;
      }

      this.$emit('change-settings', clone(this.localSettings));
    },
  },
};
</script>

<style lang="scss">
.dashboard {
  position: relative;
}
</style>
