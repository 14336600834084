<template>
  <div class="v-chart">
    <canvas ref="chart"></canvas>
  </div>
</template>

<script>
import { Chart, registerables } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

Chart.register(...registerables, ChartDataLabels);

export default {
  name: 'VChart',

  props: {
    type: { type: String, default: 'bar' },
    datasets: { type: Array, default: () => [] },
    options: { type: Object, default: () => ({}) },
    plugins: { type: Array, default: () => [] },
    labels: { type: Array, default: () => [] },
  },

  watch: {
    datasets: {
      handler(newDatasets) {
        this.$options.chart.data.datasets = newDatasets;
        this.update();
      },

      deep: true,
    },
    plugins: {
      handler(newPlugins) {
        this.$options.chart.plugins = newPlugins;
        this.update();
      },
      deep: true,
    },
    options: {
      handler(newOptions) {
        this.$options.chart.options = newOptions;
        this.update();
      },
      deep: true,
    },
    labels: {
      handler(newLabels) {
        this.$options.chart.data.labels = newLabels;
        this.update();
      },
      deep: true,
    },
    type(newType) {
      this.$options.chart.type = newType;
      this.update();
    },
  },

  mounted() {
    this.createChart();
  },

  destroyed() {
    this.$options.chart.destroy();
  },

  methods: {
    createChart() {
      if (this.$options.chart) this.$options.chart.destroy();

      this.$options.chart = new Chart(this.$refs.chart.getContext('2d'), {
        type: this.type,

        plugins: this.plugins,

        data: {
          datasets: this.datasets,
          labels: this.labels,
        },

        options: this.options,
      });
    },

    update() {
      this.$options.chart.update();
    },
  },
  chart: null,
};
</script>

<style lang="scss">
.v-chart {
  margin-top: 32px;
  position: relative;
  height: 350px;
  width: auto;
  canvas {
    @media (max-width: map.get($--screens, 'sm')) {
      width: 320px;
      height: 320px;
    }
  }
}
</style>
