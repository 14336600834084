<template>
  <base-modal v-model="modelWrapper" content-class="dashboard-modal-filters" :title="$t('dashboard.filter')">
    <dashboard-filters v-model="localFilters" :mode="mode" />
    <v-row class="mt-8" no-gutters>
      <v-col class="pr-1 pr-md-2" :cols="6">
        <v-btn block large elevation="0" color="secondary" class="primary--text" @click="closeModal">{{
          $t('modal_button.back')
        }}</v-btn>
      </v-col>

      <v-col class="pl-1 pl-md-2" :cols="6">
        <v-btn block large elevation="0" color="primary" @click="saveFilter">{{ $t('modal_button.save') }}</v-btn>
      </v-col>
    </v-row>
  </base-modal>
</template>

<script>
import BaseModal from '@/components/BaseModal.vue';
import DashboardFilters from '@/components/Dashboard/DashboardFilters.vue';
import { PAYMENTS } from '@/constants/dashboardModes';

export default {
  name: 'DashboardModalFilters',
  components: { BaseModal, DashboardFilters },
  model: {
    prop: 'isOpen',
    event: 'update:isOpen',
  },

  props: {
    isOpen: { type: Boolean, default: false },
    valueFilters: { type: Object, required: true },
    mode: { type: String, default: PAYMENTS },
  },
  data() {
    return {
      localFilters: {},
    };
  },
  computed: {
    modelWrapper: {
      get() {
        return this.isOpen;
      },

      set(value) {
        this.$emit('update:isOpen', value);
      },
    },
  },
  watch: {
    isOpen: {
      immediate: true,
      handler(value) {
        if (value) {
          this.localFilters = this.valueFilters;
        }
      },
    },
  },
  methods: {
    saveFilter() {
      this.$emit('input-filters', this.localFilters);
      this.closeModal();
    },
    closeModal() {
      this.modelWrapper = false;
    },
  },
};
</script>
<style lang="scss">
.dashboard-modal-filters {
  max-width: 360px !important;
}
</style>
