<template>
  <v-menu offset-y left>
    <template v-slot:activator="{ on, attrs }">
      <icon-button v-bind="attrs" v-on="on">
        <v-icon>
          mdi-dots-horizontal
        </v-icon>
      </icon-button>
    </template>
    <v-list>
      <v-list-item @click="openFilter">
        <v-list-item-title>
          <v-icon right>mdi-filter</v-icon>
          {{ $t('dashboard.filter') }}
        </v-list-item-title>
      </v-list-item>

      <v-list-item @click="openPeriod">
        <v-list-item-title>
          <v-icon right>mdi-calendar-month</v-icon>
          {{ $t('dashboard.period') }}
        </v-list-item-title>
      </v-list-item>

      <v-list-item @click="openSettings">
        <v-list-item-title>
          <v-icon right>mdi-cog</v-icon>
          {{ $t('dashboard.settings') }}
        </v-list-item-title>
      </v-list-item>

      <v-list-item v-show="!isTasksMode" @click="exportPayments">
        <v-list-item-title>
          <v-icon right>mdi-file-download</v-icon>
          {{ $t('button.export') }}
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
// Components
import IconButton from '@/components/IconButton.vue';

import { PAYMENTS, TASKS } from '@/constants/dashboardModes';

export default {
  name: 'DashboardMenu',

  components: { IconButton },

  props: {
    mode: { type: String, default: PAYMENTS },
  },

  computed: {
    isPaymentsMode() {
      return this.mode === PAYMENTS;
    },
    isTasksMode() {
      return this.mode === TASKS;
    },
  },

  methods: {
    openFilter() {
      this.$emit('open-filter');
    },
    openPeriod() {
      this.$emit('open-period');
    },
    openSettings() {
      this.$emit('open-settings');
    },
    exportPayments() {
      this.$emit('export-payments');
    },
  },
};
</script>
