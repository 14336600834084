export const COLOR_GRID = '#E6EAFF';
export const COLOR_TICKS = '#8f8f91';
export const COLOR_UNPAID = '#FF5252';
export const COLOR_ISSUE = '#C7D6FF';
export const COLOR_PAID = '#002FFF';
export const COLOR_TOOLTIP_BG = '#fff';

export const COLOR_TASK_PENDING = '#FF5252';
export const COLOR_TASK_IN_PROGRESS = '#002FFF';
export const COLOR_TASK_CANCELED = '#BFBFBF';
export const COLOR_TASK_COMPLETED = '#00008D';

const getPluginConfig = ({ onLeave }) => [
  {
    afterEvent: (chart, { event }) => {
      if (event.type === 'mouseout') {
        onLeave(chart);
      }
    },
  },
];

const getOptionsConfig = ({ isMobile, onLeave, tooltipController, onHover, onLegendClick, formatTicksScaleY }) => ({
  scales: {
    y: {
      beginAtZero: true,
      grid: {
        color: COLOR_GRID,
      },
      ticks: {
        color: [COLOR_TICKS],
        callback: value => {
          if (!value) return '';
          return formatTicksScaleY(value);
        },
      },
    },

    x: {
      grid: {
        display: false,
        categoryPercentage: 1.0,
        barPercentage: 1.0,
      },
      ticks: {
        color: [COLOR_TICKS],
        autoSkip: true,
      },
    },
  },

  plugins: {
    legend: {
      position: 'bottom',
      align: isMobile ? 'start' : 'center',
      labels: {
        boxWidth: isMobile ? 8 : 16,
        padding: isMobile ? 8 : 40,
        usePointStyle: isMobile,
      },
      onLeave,
      onClick: onLegendClick,
    },

    tooltip: {
      enabled: false,
      backgroundColor: COLOR_TOOLTIP_BG,
      // bodyColor: '#000',
      bodyFont: {
        size: 16,
      },
      usePointStyle: true,
      external: tooltipController,
    },
  },

  interaction: {
    mode: 'index',
    intersect: false,
  },
  // позволяет изменять размер исходя из родительского контейнера
  maintainAspectRatio: false,
  // для анимации графика при изменение убрать поле
  animation: {
    duration: 1,
  },

  onHover,
  layout: {
    padding: {
      top: 50,
    },
  },
});

const getDatasets = ({ dataSetsUnpaid, dataSetsIssue, dataSetsPaid, dataSetsLabel, barWidth }) => [
  {
    type: 'line',
    label: dataSetsLabel.unpaid,
    data: dataSetsUnpaid.data,
    datalabels: dataSetsUnpaid.datalabels,
    hidden: dataSetsUnpaid.hidden,
    backgroundColor: COLOR_UNPAID,
    borderWidth: 1.75,
    borderColor: COLOR_UNPAID,
    pointRadius: 2,
    lineTension: 0.4,
  },
  {
    label: dataSetsLabel.issue,
    data: dataSetsIssue.data,
    datalabels: dataSetsIssue.datalabels,
    hidden: dataSetsIssue.hidden,
    backgroundColor: COLOR_ISSUE,
    barThickness: barWidth,
    barPercentage: 1,
  },
  {
    label: dataSetsLabel.paid,
    data: dataSetsPaid.data,
    datalabels: dataSetsPaid.datalabels,
    hidden: dataSetsPaid.hidden,
    backgroundColor: COLOR_PAID,
    barThickness: barWidth,
    barPercentage: 1,
  },
];

const getTasksDatasets = ({
  dataSetsPending,
  dataSetsInProgress,
  dataSetsCanceled,
  dataSetsCompleted,
  dataSetsLabel,
  barWidth,
}) => [
  {
    type: 'line',
    label: dataSetsLabel.pending,
    data: dataSetsPending.data,
    datalabels: dataSetsPending.datalabels,
    hidden: dataSetsPending.hidden,
    backgroundColor: COLOR_TASK_PENDING,
    borderWidth: 1.75,
    borderColor: COLOR_TASK_PENDING,
    pointRadius: 2,
    lineTension: 0.4,
  },
  {
    type: 'line',
    label: dataSetsLabel.inProgress,
    data: dataSetsInProgress.data,
    datalabels: dataSetsInProgress.datalabels,
    hidden: dataSetsInProgress.hidden,
    backgroundColor: COLOR_TASK_IN_PROGRESS,
    borderWidth: 1.75,
    borderColor: COLOR_TASK_IN_PROGRESS,
    pointRadius: 2,
    lineTension: 0.4,
  },
  {
    label: dataSetsLabel.canceled,
    data: dataSetsCanceled.data,
    datalabels: dataSetsCanceled.datalabels,
    hidden: dataSetsCanceled.hidden,
    backgroundColor: COLOR_TASK_CANCELED,
    barThickness: barWidth,
    barPercentage: 1,
  },
  {
    label: dataSetsLabel.completed,
    data: dataSetsCompleted.data,
    datalabels: dataSetsCompleted.datalabels,
    hidden: dataSetsCompleted.hidden,
    backgroundColor: COLOR_TASK_COMPLETED,
    barThickness: barWidth,
    barPercentage: 1,
  },
];

export function getConfigChartjs({
  onLeave,
  dataSetsUnpaid,
  dataSetsIssue,
  dataSetsPaid,
  dataSetsLabel,
  barWidth,
  isMobile,
  tooltipController,
  onHover,
  onLegendClick,
  formatTicksScaleY,
}) {
  return {
    plugins: getPluginConfig({ onLeave }),
    datasets: getDatasets({ dataSetsUnpaid, dataSetsIssue, dataSetsPaid, dataSetsLabel, barWidth }),
    options: getOptionsConfig({
      isMobile,
      onLeave,
      tooltipController,
      onHover,
      onLegendClick,
      formatTicksScaleY,
    }),
  };
}

export function getConfigTasksChartjs({
  onLeave,
  dataSetsPending,
  dataSetsInProgress,
  dataSetsCanceled,
  dataSetsCompleted,
  dataSetsLabel,
  barWidth,
  isMobile,
  tooltipController,
  onHover,
  onLegendClick,
  formatTicksScaleY,
}) {
  return {
    plugins: getPluginConfig({ onLeave }),
    datasets: getTasksDatasets({
      dataSetsPending,
      dataSetsInProgress,
      dataSetsCanceled,
      dataSetsCompleted,
      dataSetsLabel,
      barWidth,
    }),
    options: getOptionsConfig({
      isMobile,
      onLeave,
      tooltipController,
      onHover,
      onLegendClick,
      formatTicksScaleY,
    }),
  };
}

export function getSettingsPaymentsChart(
  { issueDisplay, issueIsShowValue, paidDisplay, paidIsShowValue, unpaidDisplay, unpaidIsShowValue } = {
    issueDisplay: true,
    issueIsShowValue: false,
    paidDisplay: true,
    paidIsShowValue: false,
    unpaidDisplay: true,
    unpaidIsShowValue: false,
  }
) {
  return {
    issue: {
      display: issueDisplay,
      isShowValue: issueIsShowValue,
    },
    paid: {
      display: paidDisplay,
      isShowValue: paidIsShowValue,
    },
    unpaid: {
      display: unpaidDisplay,
      isShowValue: unpaidIsShowValue,
    },
  };
}

export function getSettingsTasksChart(
  {
    pendingDisplay,
    pendingIsShowValue,
    inProgressDisplay,
    inProgressIsShowValue,
    canceledDisplay,
    canceledIsShowValue,
    completedDisplay,
    completedIsShowValue,
  } = {
    pendingDisplay: true,
    pendingIsShowValue: false,
    inProgressDisplay: true,
    inProgressIsShowValue: false,
    canceledDisplay: true,
    canceledIsShowValue: false,
    completedDisplay: true,
    completedIsShowValue: false,
  }
) {
  return {
    pending: {
      display: pendingDisplay,
      isShowValue: pendingIsShowValue,
    },
    inProgress: {
      display: inProgressDisplay,
      isShowValue: inProgressIsShowValue,
    },
    canceled: {
      display: canceledDisplay,
      isShowValue: canceledIsShowValue,
    },
    completed: {
      display: completedDisplay,
      isShowValue: completedIsShowValue,
    },
  };
}
