<template>
  <base-modal v-model="modelWrapper" content-class="dashboard-modal-period" :title="$t('dashboard.filter')">
    <calendar-with-panel :value="periodFilter" @input="inputPeriod" @save-date="savePeriod" @cancel-menu="cancelMenu" />
  </base-modal>
</template>

<script>
import BaseModal from '@/components/BaseModal.vue';
import CalendarWithPanel from '@/components/CalendarWithPanel.vue';

export default {
  name: 'DashboardModalPeriod',
  components: { BaseModal, CalendarWithPanel },
  model: {
    prop: 'isOpen',
    event: 'update:isOpen',
  },

  props: {
    isOpen: { type: Boolean, default: false },
    periodFilter: { type: Array, required: true },
  },
  data() {
    return {
      localPeriod: [],
    };
  },
  computed: {
    modelWrapper: {
      get() {
        return this.isOpen;
      },

      set(value) {
        this.$emit('update:isOpen', value);
      },
    },
  },
  watch: {
    isOpen: {
      immediate: true,
      handler(value) {
        if (value) {
          this.localPeriod = this.periodFilter;
        }
      },
    },
  },
  methods: {
    closeModal() {
      this.modelWrapper = false;
    },
    inputPeriod(periodFilter) {
      this.localPeriod = periodFilter;
    },
    savePeriod() {
      this.$emit('input-period', this.localPeriod);
      this.closeModal();
    },
    cancelMenu() {
      this.$emit('input-period', []);
      this.closeModal();
    },
  },
};
</script>
<style lang="scss">
.dashboard-modal-period {
  max-width: 360px !important;
}
</style>
