<template>
  <div class="dashboard-summary">
    <h3 class="dashboard-summary__title">
      {{ $t('dashboard.summary') }}
    </h3>
    <v-row dense>
      <v-col v-for="card in summaryCards" :key="card.id" :cols="media.isMobile ? 6 : 12 / summaryCards">
        <base-card class="dashboard-summary__card" :loading="loading">
          <template v-slot:header>
            <div class="dashboard-summary__card-header">
              <div class="dashboard-summary__card-indicator" :style="`background: ${card.color}`"></div>
              <h6 class="dashboard-summary__card-title">
                {{ media.isMobile ? $t(card.mobileTitle) : $t(card.title) }}
              </h6>
            </div>
          </template>
          <p class="dashboard-summary__card-text">
            {{ formatValue(card.value) }} <span v-if="card.title === 'dashboard.totalArea'">m<sup>2</sup></span>
          </p>
        </base-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import BaseCard from '@/components/BaseCard.vue';
import formatUnitsValue from '@/utils/unitsFormatting';
import { PAYMENTS, TASKS } from '@/constants/dashboardModes';

const summaryPaymentsConfig = [
  {
    id: 1,
    title: 'dashboard.invoices_issue',
    mobileTitle: 'dashboard.issue',
    name: 'invoicesIssued',
    color: '#C7D6FF',
  },
  { id: 2, title: 'dashboard.invoices_paid', mobileTitle: 'dashboard.paid', name: 'invoicesPaid', color: '#0C30F7' },
  {
    id: 3,
    title: 'dashboard.invoices_unpaid',
    mobileTitle: 'dashboard.unpaid',
    name: 'invoicesPending',
    color: '#FF5252',
  },
];

const summaryTasksConfig = [
  {
    id: 1,
    title: 'dashboard.tasks_pending',
    mobileTitle: 'dashboard.tasks_pending',
    name: 'pendingTasks',
    color: '#FF5252',
  },
  {
    id: 2,
    title: 'dashboard.tasks_in_progress',
    mobileTitle: 'dashboard.tasks_in_progress',
    name: 'inProgressTasks',
    color: '#002FFF',
  },
  {
    id: 3,
    title: 'dashboard.tasks_canceled',
    mobileTitle: 'dashboard.tasks_canceled',
    name: 'canceledTasks',
    color: '#BFBFBF',
  },
  {
    id: 4,
    title: 'dashboard.tasks_completed',
    mobileTitle: 'dashboard.tasks_completed',
    name: 'completedTasks',
    color: '#00008D',
  },
];

export default {
  name: 'DashboardSummary',
  components: { BaseCard },
  props: {
    cards: { type: Object, required: true },
    loading: { type: Boolean, default: false },
    unitsMeasurement: { type: String, default: 'pcs' },
    mode: { type: String, required: true },
  },

  inject: ['media'],
  computed: {
    summaryCards() {
      let test = null;
      if (this.isPaymentsMode) {
        test = summaryPaymentsConfig.map(({ name, ...config }) => {
          return { ...config, value: this.cards[name] };
        });
      }

      if (this.isTasksMode) {
        test = summaryTasksConfig.map(({ name, ...config }) => {
          return { ...config, value: this.cards[name] };
        });
      }

      return test;
    },
    isPaymentsMode() {
      return this.mode === PAYMENTS;
    },
    isTasksMode() {
      return this.mode === TASKS;
    },
  },
  methods: {
    formatValue(value) {
      if (this.loading) {
        return '';
      }
      if (value === null) {
        return this.$t('label.not_data_filter');
      }
      return formatUnitsValue({ value, units: this.unitsMeasurement });
    },
  },
};
</script>

<style lang="scss">
.dashboard-summary {
  &__card {
    min-height: 104px;
    @media (max-width: map.get($--screens, 'sm')) {
      min-height: 76px;
    }
  }
  &__card-header {
    display: flex;
    align-items: center;
  }
  &__card-indicator {
    border-radius: 100px;
    width: 8px;
    height: 8px;
    margin-right: 8px;
  }
  &__title {
    font-weight: $--font-weight-medium;
    font-size: 20px;
    line-height: 32px;
    margin-bottom: 8px;
  }
  &__card {
    height: 100%;
  }
  &__card-title {
    font-weight: $--font-weight-normal;
    font-size: 16px;
    line-height: 24px;
    opacity: 0.5;
    @media (max-width: map.get($--screens, 'sm')) {
      font-size: 12px;
      line-height: 20px;
    }
  }
  &__card-text {
    font-weight: $--font-weight-medium;
    font-size: 34px;
    line-height: 44px;
    font-weight: 500;

    @media (max-width: map.get($--screens, 'sm')) {
      font-size: 24px;
      line-height: 34px;
    }
    @media (max-width: map.get($--screens, 'xs')) {
      font-size: 18px;
      line-height: 26px;
    }
  }
}
</style>
