<template>
  <div class="v-chart-tooltip rounded elevation-1 pb-2" :style="cssVars">
    <table>
      <tr v-for="dataPoint in dataPoints" :key="`${dataPoint.dataIndex}-${dataPoint.datasetIndex}`" class="pb-2">
        <td class="d-flex align-center flex-nowrap v-chart-tooltip__td">
          <span
            class="v-chart-tooltip__circle mr-2"
            :style="{ background: dataPoint.element.options.backgroundColor }"
          ></span
          ><span class="v-chart-tooltip__label">{{ dataPoint.dataset.label }}:</span>
        </td>
        <td class="pl-2 v-chart-tooltip__td">
          <span class="v-chart-tooltip__text">{{ dataPoint.raw.y }}</span>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  name: 'VChartTooltip',

  props: {
    top: { type: [Number, String], default: 0 },
    left: { type: [Number, String], default: 0 },
    opacity: { type: [Number, String], default: 0 },
    dataPoints: { type: Array, default: () => [] },
  },

  computed: {
    cssVars() {
      return {
        '--v-chart-tooltip-top': `${this.top}px`,
        '--v-chart-tooltip-left': `${this.left}px`,
        '--v-chart-tooltip-opacity': this.opacity,
      };
    },
  },
};
</script>

<style lang="scss">
.v-chart-tooltip {
  position: absolute;
  padding: 16px;
  background: $--white-color-0;
  top: var(--v-chart-tooltip-top, 0);
  left: var(--v-chart-tooltip-left, 0);
  opacity: var(--v-chart-tooltip-opacity, 0);
  transition: all 0.1s ease;
  pointer-events: none;
  z-index: 3;
  &__td {
    white-space: nowrap;
  }
  &__label {
    font-weight: $--font-weight-normal;
    color: $--black-color-0;
    opacity: 0.5;
  }
  &__text {
    font-weight: $--font-weight-medium;
  }
  &__circle {
    display: block;
    width: 8px;
    height: 8px;
    border-radius: 100%;
  }
}
</style>
