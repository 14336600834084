import * as serializers from '@/constants/serializer';
import * as schemaItemTypes from './schemaItemTypes';
import * as onUpdateEffects from './onUpdateEffects';
import * as dictionaryTypes from './dictionaryTypes';

const dependentProperties = {
  PROJECTS: 'projects',
  CLIENTS: 'clients',
  PERFORMERS: 'performers',
  MANY_SERVICE_TYPES: 'servicesTypes',
  MANY_CONTRACTORS_TYPES: 'contractors',
  PAYMENT_TYPE: 'paymentTypes',
};

/**
 * @type {Array<import('./schema').default>}
 */

export const schema = [
  {
    prop: 'unitsMeasurement',
    type: schemaItemTypes.ENUM,
    dictionary: dictionaryTypes.UNITS_MEASUREMENT,
    // payload: [{ maxWidth: '85px' }],
  },
  {
    type: schemaItemTypes.MANY_SERVICE_TYPES,
    label: 'label.services_types',
    prop: dependentProperties.MANY_SERVICE_TYPES,
  },
  {
    type: schemaItemTypes.MANY_CONTRACTORS_TYPES,
    label: 'label.contractors',
    prop: dependentProperties.MANY_CONTRACTORS_TYPES,
  },
  {
    type: schemaItemTypes.MANY_PROJECTS,
    label: 'label.projects',
    prop: dependentProperties.PROJECTS,
    payload: [
      {
        param: 'clients',
        from: [dependentProperties.CLIENTS],
        serializer: serializers.EXTRACT_PARAMS_FROM_MULTI_SELECT_OBJECT,
        onlyEffect: true,
      },
    ],
    onUpdate: [
      {
        type: onUpdateEffects.RESET,
        to: dependentProperties.CLIENTS,
      },
    ],
  },
  {
    type: schemaItemTypes.MANY_CLIENTS,
    label: 'label.clients',
    prop: dependentProperties.CLIENTS,
    payload: [
      {
        param: 'projects',
        from: [dependentProperties.PROJECTS],
        cache: true,
        serializer: serializers.EXTRACT_PARAMS_FROM_MULTI_SELECT_OBJECT,
      },
    ],
    onUpdate: [
      {
        type: onUpdateEffects.AUTOCOMPLETE_MANY,
        to: dependentProperties.PROJECTS,
      },
    ],
  },
  {
    type: schemaItemTypes.MANY_PAYMENT_TYPE,
    label: 'label.payment_types',
    prop: dependentProperties.PAYMENT_TYPE,
  },
  {
    label: 'dashboard.date_period_label',
    type: schemaItemTypes.DATE_WITH_PANEL,
    prop: 'datePeriod',
  },
  // {
  //   prop: 'accounting',
  //   type: schemaItemTypes.ENUM,
  //   dictionary: dictionaryTypes.ACCOUNTING,
  //   tooltip: { text: 'dashboard.accounting_tooltip', icon: 'mdi-help-circle' },
  // },
];

export const taskSchema = [
  {
    type: schemaItemTypes.MANY_SERVICE_TYPES,
    label: 'label.services_types',
    prop: dependentProperties.MANY_SERVICE_TYPES,
  },
  {
    type: schemaItemTypes.MANY_PROJECTS,
    label: 'label.projects',
    prop: dependentProperties.PROJECTS,
    payload: [
      {
        param: 'clients',
        from: [dependentProperties.CLIENTS],
        serializer: serializers.EXTRACT_PARAMS_FROM_MULTI_SELECT_OBJECT,
        onlyEffect: true,
      },
    ],
    onUpdate: [
      {
        type: onUpdateEffects.RESET,
        to: dependentProperties.CLIENTS,
      },
    ],
  },
  {
    type: schemaItemTypes.MANY_CLIENTS,
    label: 'label.clients',
    prop: dependentProperties.CLIENTS,
    payload: [
      {
        param: 'projects',
        from: [dependentProperties.PROJECTS],
        cache: true,
        serializer: serializers.EXTRACT_PARAMS_FROM_MULTI_SELECT_OBJECT,
      },
    ],
    onUpdate: [
      {
        type: onUpdateEffects.AUTOCOMPLETE_MANY,
        to: dependentProperties.PROJECTS,
      },
    ],
  },
  {
    type: schemaItemTypes.MANY_PERFORMERS,
    label: 'label.performers',
    prop: dependentProperties.PERFORMERS,
  },
  {
    label: 'dashboard.date_period_label',
    type: schemaItemTypes.DATE_WITH_PANEL,
    prop: 'datePeriod',
  },
];

export const schemaForMobile = schema.filter(item => item.type !== schemaItemTypes.DATE_WITH_PANEL);
export const taskSchemaForMobile = taskSchema.filter(item => item.type !== schemaItemTypes.DATE_WITH_PANEL);

export { schema as default };
