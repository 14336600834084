<template>
  <div class="base-card__wrapper">
    <v-card flat class="base-card" :loading="loading">
      <div class="base-card__header">
        <slot name="header"></slot>
      </div>
      <div class="base-card__body">
        <slot></slot>
      </div>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'BaseCard',
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss">
.base-card {
  &__wrapper {
    box-shadow: $--shadow-main;
  }
  &__header {
    margin: 0 0 8px 16px;
    padding-top: 16px;
    @media (max-width: map.get($--screens, 'sm')) {
      margin: 0 0 4px 8px;
      padding-top: 8px;
    }
  }
  &__body {
    margin-left: 16px;
    padding-bottom: 16px;
    @media (max-width: map.get($--screens, 'sm')) {
      margin-left: 8px;
      padding-bottom: 8px;
    }
  }
}
</style>
