<template>
  <div class="v-chart-message" :style="cssVars">
    {{ $t(message) }}
  </div>
</template>

<script>
export default {
  name: 'VChartMessage',

  props: {
    message: { type: String, default: '' },
    opacity: { type: [Number, String], default: 0 },
  },
  computed: {
    cssVars() {
      return {
        '--v-chart-bottom-line-opacity': this.opacity,
        '--v-chart-bottom-line-z-index': this.opacity ? 1 : 0,
      };
    },
  },
};
</script>

<style lang="scss">
.v-chart-message {
  position: absolute;
  left: 0;
  top: 50px;
  bottom: 70px;
  right: 0;
  opacity: var(--v-chart-bottom-line-opacity, 0);
  font-weight: $--font-weight-normal;
  font-size: 24px;
  line-height: 32px;
  color: #1e1e22;
  display: flex;
  align-items: center;
  z-index: var(--v-chart-bottom-line-z-index, 0);
  justify-content: center;
}
</style>
