<template>
  <form-builder
    ref="formBuilder"
    v-model="valueModel"
    enable-cache
    remove-label-suffix
    class="dashboard-filters"
    :schema="schema"
    :initial-data="value"
  >
  </form-builder>
</template>

<script>
import FormBuilder from '@/components/schema/FormBuilder.vue';

import { schema, schemaForMobile, taskSchema, taskSchemaForMobile } from '@/schemas/dashboardFilter.schema';

import { createModelWrapper } from '@/utils/components';
import { PAYMENTS, TASKS } from '@/constants/dashboardModes';

export default {
  name: 'DashboardFilters',
  components: { FormBuilder },
  inject: ['media'],
  props: {
    value: {
      type: Object,
      required: true,
    },
    mode: {
      type: String,
      default: PAYMENTS,
    },
  },

  computed: {
    schema() {
      if (this.mode === TASKS) {
        return this.media.isMobile ? taskSchemaForMobile : taskSchema;
      }

      return this.media.isMobile ? schemaForMobile : schema;
    },
    valueModel: createModelWrapper('value', 'input'),
  },
};
</script>

<style lang="scss">
.dashboard-filters {
  margin-bottom: 32px;
  .v-form {
    display: flex;
    @media (max-width: map.get($--screens, 'sm')) {
      flex-direction: column;
    }
    span {
      flex-grow: 1;
      margin-left: 8px;
      margin-right: 8px;
      display: block;
      width: calc(100% / 6);
      @media (max-width: map.get($--screens, 'sm')) {
        width: 100%;
        margin-left: 0;
        margin-right: 0;
      }

      &.mini-width {
        max-width: 85px;
      }

      &:first-child {
        margin-left: 0;
        @media (max-width: map.get($--screens, 'sm')) {
          max-width: 100%;
        }
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }
  .theme--light.v-text-field > .v-input__control > .v-input__slot:before {
    border-color: rgba(0, 0, 196, 0.15) !important;
  }
  &__select {
    .v-input__slot {
      padding-bottom: 6px;
      padding-left: 12px;
    }
  }
}
</style>
